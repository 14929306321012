export const colors = {
  loginBackground: '#feb723',
  darkBackground: '#282c34',
  darkHover: '#282c34',
  dark: '#000000',
  lightBackground: '#fff',
  darkRed: 'rgb(110 38 59)',
  darkOverlay:
    'linear-gradient(90deg, rgb(16 28 42 / 95%) 0%, rgb(25 28 51 / 70%) 45%, rgb(39 31 56 / 75%) 100%)',
  primary: '#7464f3',
  secondary: '#4da6de',
  text: '#6d7688',
  light: 'rgb(92, 110, 146)',
  lightHover: 'rgb(123, 143, 179)',
  white: 'white',
  background: 'rgb(231, 239, 255)',
  borders: 'black',
  error: 'red',
  grey: '#827d7d',
};
